<template>
    <div>
        <div class="elePaySuccess">
            <Header backType="blank" :back="true">
                <span slot="headText">支付异常</span>
            </Header>
            <div>
                <Svgs name="iconwarn" color="#d81e06" classes="icon-success"></Svgs>
                <div class="elePaySuccessTitle">订单支付异常</div>
                <div class="elePaySuccessMount">￥{{parseFloat(mealItemDetail.settleAmount).toFixed(2)}}</div>
                <div class="elePaySuccessContent">
                    <div class="card">
                        <div>商户信息</div>
                        <div>{{mealItemDetail.shopName}}</div>
                    </div>
                    <div class="card">
                        <div>饿了么订单号</div>
                        <div>{{orderDetail.extOrderId}}</div>
                    </div>
                </div>
                <div class="elePaySuccessTxt">
                    <span>*</span>
                    如遇支付问题,请致电
                    <span @click="ihtml.telCall('01056304858')" v-html="phoneText"></span>
                </div>
                
            </div>
        </div>
        <div class="elePaySuccessBtnGroup">
            <Btn btnClass="elePaySuccessBtn elePaySuccessDis" @callBack="backPath">
                <span slot="btnContent">关闭</span>
            </Btn>
            <Btn btnClass="elePaySuccessBtn" @callBack="ihtml.telCall('01056304858')">
                <span slot="btnContent">联系客服</span>
            </Btn>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { miceService } from "@/service/miceService.js";
import { phoneList } from '@/config/phone/index.js'
export default {
    data() {
        return {
            mealItemDetail: {}
        }
    },
    computed: {
        ...mapGetters([
            "orderDetail",
            "event"
        ]),
        phoneText: {
            get: function() {
                let tenant = this.event.tenant_code || 'pfizer';
                return phoneList[tenant];  
            }    
        }
    },
    created() {
        const params= {
            itemId:this.orderDetail.itemId
        }
        miceService.getMealItemDetail(params).then(res => {
            if(res && res.success) {
                this.mealItemDetail = res.content
            }
        })
    },
    methods: {
        backPath() {
            this.$router.push({path: '/orderList'})
        }
    }
};
</script>
<style lang="scss">
@import "ElePayResult.scss";
</style>
