
const novartis = '专属客服（客户代码400）'
const dscl = '专属客服'
const pfizer = 'YES客服（客户代码888）'
const roche = 'A1客服（客户代码648）'

export const phoneList = {
    novartis: novartis,
    dscl: dscl,
    pfizer: pfizer,
    crossevent: dscl,
    roche: roche
}